/*----------------------------------------------------------------------------*/
/*  #STAGES                                                                   */
/*----------------------------------------------------------------------------*/
.stage {
  background: var(--color_blue-0);
  height: 20rem;
  margin: -2rem 0 1.7rem;
  overflow: hidden;
  width: 100%;
  @media (max-width: 57rem) {
    height: 20rem;
  }
  @media (max-width: 50rem) {
    height: 18rem;
  }
  @media (max-width: 30rem) {
    height: 15rem;
  }
  @media (max-width: 20rem) {
    height: 10rem;
  }
  figure,
  picture {
    margin: 0;
    padding: 0;
    height: inherit;
  }
  img, 
  &__image {
    display: block;
    height: 100%;
    margin: 0 auto;
    object-fit: cover;
    width: 100%;
  }
}
