/*----------------------------------------------------------------------------*/
/*  #COLORS                                                                   */
/*----------------------------------------------------------------------------*/
:root {
  --color_black-0:  rgba(0, 0, 0, 1);                              /* #000000 */
  --color_white-0:  rgba(255, 255, 255, 1);                        /* #FFFFFF */
  --color_blue-0:   rgba(0, 113, 185, 1);       /* ci blue -          #0071B9 */
  --color_pink-0:   rgba(237, 1, 121, 1);       /* ci pink -          #ed0179 */
  --color_grey-0:   rgba(242, 242, 242, 1);                        /* #F2F2F2 */
  --color_grey-1:   rgba(167, 167, 167, 1);                        /* #A7A7A7 */
  --color_grey-2:   rgba(221,221,221);                             /* #444444 */
  --color_grey-3:   rgba(63, 63, 63, 1);                           /* #3F3F3F */
  --color_grey-4:   rgba(40, 40, 40, 1);                           /* #282828 */
  --color_grey-5:   rgba(89, 89, 89, 1);                           /* #595959 */
  --color_grey-6:   rgba(118, 118, 118, 1);                        /* #767676 */
  --color_grey-7:   rgba(149, 149, 149, 1);                        /* #959595 */
}
