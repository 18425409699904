/*----------------------------------------------------------------------------*/
/*  #ACCESSIBILITY                                                            */
/*----------------------------------------------------------------------------*/
/* Hide visually but allow focus */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 0.063rem;
  margin: -0.063rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0.063rem;
  .focusable {
    &:active,
    &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      white-space: inherit;
      width: auto;
    }
  }
}

/* Allow the Skip link to be focusable when navigated to via keyboard */
.skip-link.visually-hidden.focusable:focus {
  padding: .2rem 0;
}
