/*----------------------------------------------------------------------------*/
/*  #FONT-SIZES                                                               */
/*----------------------------------------------------------------------------*/
:root {
  --font-size_xxxs: 0.6rem;
  --font-size_xxs:  0.8rem;
  --font-size_xs:   0.9rem;
  --font-size_s:    1rem;
  --font-size_m:    1.1rem;
  --font-size_l:    1.2rem;
  --font-size_xl:   1.3rem;
  --font-size_xxl:  1.9rem;
}
