/*----------------------------------------------------------------------------*/
/*  # HANDELSPARTNER                                                          */
/*----------------------------------------------------------------------------*/
.dealers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.6rem;
  list-style: none;
  margin: 0;
  padding: 0;
  &__list-item {
    display: flex;
    flex-basis: calc(100% / 2 -  1.2rem);
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 1;
  }
  &__logo {
    border: 0.004rem solid var(--color_grey-1);
    padding: 0.1rem;
  }
}
