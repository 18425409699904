/*----------------------------------------------------------------------------*/
/*  #IMPORTS - from /_sass/                                                   */
/*----------------------------------------------------------------------------*/
@import "accessibility";                      /* _sass/_accessibility.scss    */
@import "colors";                             /* _sass/_colors.scss           */
@import "font-sizes";                         /* _sass/_font-sizes.scss       */
@import "gradients";                          /* _sass/_gradients.scss        */
@import "handelspartner";                     /* _sass/_handelspartner.scss   */
@import "lists";                              /* _sass/_lists.scss            */
@import "sitemap";                            /* _sass/_sitemap.scss          */
@import "stages";                             /* _sass/_stages.scss           */
@import "typefaces";                          /* _sass/_typefaces.scss        */
@import "wrapper";                            /* _sass/_wrapper.scss          */




/*----------------------------------------------------------------------------*/
/*  # DEFAULTS                                                                */
/*----------------------------------------------------------------------------*/
html {
  background:  var(--color_white-0);
  height: 100%;
}

body {
  background: var(--color_white-0);
  color: var(--color_black-0);
  margin: 0 auto;
}





/*----------------------------------------------------------------------------*/
/*  #HEADER                                                                   */
/*----------------------------------------------------------------------------*/
.website-header{
  background: var(--color_blue-0);
  border-bottom: .4rem solid transparent;
  border-image: url(/assets/graphics/border.svg) 40 round;
  margin: 0 0 2rem;
  &__logo {
    img {
      padding: 1rem 0;
    }
  }
}




/*----------------------------------------------------------------------------*/
/*  # HEADINGS                                                                */
/*----------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {
  color: var(--color_blue-0);
  font-family: var(--typeface_400);
  font-weight: normal;
}

h1 {
  font-family: var(--typeface_500);
  font-size: var(--font-size_xxl); 
}

h2 {
  font-family: var(--typeface_400);
  font-size: var(--font-size_xl); 
}

h3 {
  font-family: var(--typeface_400);
  font-size: var(--font-size_l); 
}

h4, h5, h6 {
  font-family: var(--typeface_400);
  font-size: var(--font-size_m); 
}






/*----------------------------------------------------------------------------*/
/*  # TEXT-LEVEL                                                              */
/*----------------------------------------------------------------------------*/
main {
  p {
    font-family: var(--typeface_300);
    font-size: var(--font-size_s);
    margin: 0 0 1.4rem;
  }
  b,
  strong,
  th {
    font-family: var(--typeface_500);
    color: var(--color_grey-3);
    font-weight: normal;
    i,em {
      font-family: var(--typeface_300italic);
    }
  }
  li {
    font-family: var(--typeface_300);
    font-size: var(--font-size_s);
  }
  blockquote,
  blockquote > p,
  cite,
  em,
  i {
    font-family: var(--typeface_300italic);
    font-style: normal;
  }
  a,
  a:link,
  a:visited {
    color: var(--color_blue-0);
    text-decoration: none;
    overflow-wrap: break-word;
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }
  abbr {
    cursor: help;
  }
  hr {
    background: none;
    border: none;
    border-bottom: 0.004rem solid var(--color_grey-2);
    margin: 2.4rem 0 2.8rem;
  }
}





/*----------------------------------------------------------------------------*/
/*  # PRODUCT BOXES                                                           */
/*----------------------------------------------------------------------------*/
.product-boxes {
  align-content: flex-start;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.2rem;
  margin: 1.6rem 0 0 0;
}
.product-box {
  align-self: flex-start;
  position: relative;
  width: 89vw;
  flex-basis: 10rem;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  margin: 1.6rem 0 1.5rem;
  min-height: 8rem;
  text-align: center;
  width: calc(100% + 1.6rem);
  a.product-box__anchor {
    margin-top: auto;
    align-self: flex-start;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }
  img {
    display: block;
    margin: 0 auto;
  }
  span {
    color: var(--color_black-0);
    font-family: var(--typeface_400);
    font-size: var(--font-size_xxs);
  }
  h2.product-box__button {
    background: var(--color_blue-0);
    border-radius: 0.2rem;
    color: var(--color_white-0);
    font-family: var(--typeface_500);
    font-size: var(--size_xl);
    display: block;
    padding: .3em .5em;
    text-align: center;
    text-decoration: none;
    &:hover {
      background: var(--color_pink-0);
    }
  }
}





/*----------------------------------------------------------------------------*/
/*  # PRODUCT                                                                 */
/*----------------------------------------------------------------------------*/
.product {
  border-bottom: 0.004rem solid var(--color_grey-1);
  border-top: 0.004rem solid var(--color_grey-1);
  margin: 2.4rem 0;
  padding: 1rem 0 0 0;
  h2 {
    color: var(--color_grey-3);
    display: inline;
    font-family: var(--typeface_400);
    font-size: var(--font-size_xs);
    margin: 0;
  }
  &__article-number {
    color: var(--color_grey-3);
    font-family: var(--typeface_300);
    font-size: var(--font-size_xs);
    margin: 0 0 .5rem;
  }
  &__image {
    display: block;
    margin: 1rem auto 2rem;
  }
  &__details {
    background: var(--grad-1);
    column-count: 3;
    column-gap: 2.5rem;
    margin: 0 0 2.5rem 0;
    padding: 0 1rem .4rem 1rem;
    @media (max-width: 37.5rem) { 
      column-count: 2;
      column-gap: 1.5rem;
    }
    @media (max-width: 18.75rem) { 
      column-count: 1;
    }
    li {
      font-family: var(--typeface_300);
      font-size: var(--font-size_xs);
      list-style: none;
      margin: 0 0 1rem 0;
    }
    span {
      display: block;
      font-family: var(--typeface_400);
      font-size: var(--font-size_s);
    }
  }
}




/*----------------------------------------------------------------------------*/
/*  # FOOTER                                                                  */
/*----------------------------------------------------------------------------*/
.website-footer {
  background: var(--color_grey-0);
  font-size: var(--font-size_s);
  margin: 3.5rem 0 0;
  padding: 0;
  &:before {
    clear: both;
    content: " ";
  }
}

.website-footer-navigation {
  flex-grow: 2;
  flex-shrink: 1;
  flex-basis: 40%;
  align-items: flex-start;
  align-content: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0 2.5rem;

  &__flex-items {
    flex-basis: 10rem;
    flex-grow: 1;
    padding: 0 1rem;
    text-align: center;

    &:first-of-type {
      padding: 0 1rem 0 0;

      @media (max-width: 35rem) {
        padding: 0 1rem;
      }
    }

    &:last-of-type {
      padding: 0 0 0 1rem;
      @media (max-width: 35rem) {
        padding: 0 1rem;
      }
    }

    @media (max-width: 35rem) {
      padding: 0 1rem;
    }
  }

  &__heading {
    border-bottom: 0.01rem dotted var(--color_grey-4);
    color: var(--color_black-0);
    font-family: var(--typeface_400);
    font-size: var(--font-size_xs);
    font-weight: normal;
    text-align: left;
    margin: 2.5rem 0 .8rem;
    padding: 0 0 .6rem;
  }
  &__description {
    color: var(--color_black-0);
    font-family: var(--typeface_400);
    font-size: var(--font-size_xxs);
    font-weight: normal;
    text-align: left;
    margin: .8rem 0;
  }

  ul,
  &__list {
    list-style: outside none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    font-size: var(--font-size_xs);
    margin: 0;
    padding: 0;
    text-align: left;
  }

  a,
  &__anchor {
    font-family: var(--typeface_300);
    color: var(--color_blue-0);
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: var(--color_black-0);
      text-decoration: underline;
    }
  }
}

.website-footer-bottom {
  background: var(--color_grey-2);
  color: var(--color_black-0);
  padding: .8rem 0 1rem;

  &__copyright {
    font-family: var(--typeface_400);
    font-size: var(--font-size_xxs);
    margin: 0 .4rem 0 0;
  }

  &__list {
    margin: 0;
    padding: 0;
    li {
      display: inline;
      padding: 0 .625rem 0 0;
      &:last-child {
        padding: 0;
      }
      a,
      &__anchor {
        font-family: var(--typeface_300);
        font-size: var(--font-size_xxs);
        color: var(--color_black-0);
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}
