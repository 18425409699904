/*----------------------------------------------------------------------------*/
/*  #LISTS                                                                    */
/*----------------------------------------------------------------------------*/
.list {
 &__listing-1 {
  li {
    display: flow-root list-item;
    margin: 0 0 1.4rem 0;
    figure {
      float: right;
      margin: 0 0 .5rem 1rem;
      padding: 0;
      img {
        height: 145px;
        width: 250px;
      }
    }
  }
 } 
}
