/*----------------------------------------------------------------------------*/
/*  #TYPEFACE VARIABLES                                                       */
/*----------------------------------------------------------------------------*/

/* Roman */
:root {
  --typeface_100: '100';
}
@font-face {
  font-family: '100';
  font-display: fallback;
  src: url('/assets/fonts/roboto-100.woff2') format('woff2');
}

:root {
  --typeface_100italic: '100italic';
}
@font-face {
  font-family: '100italic';
  font-display: fallback;
  src: url('/assets/fonts/roboto-100italic.woff2') format('woff2');
}


:root {
  --typeface_300: '300';
}
@font-face {
  font-family: '300';
  font-display: fallback;
  src: url('/assets/fonts/roboto-300.woff2') format('woff2');
}

:root {
  --typeface_300italic: '300italic';
}
@font-face {
  font-family: '300italic';
  font-display: fallback;
  src: url('/assets/fonts/roboto-300italic.woff2') format('woff2');
}


:root {
  --typeface_400: '400';
}
@font-face {
  font-family: '400';
  font-display: fallback;
  src: url('/assets/fonts/roboto-400.woff2') format('woff2');
}

:root {
  --typeface_400italic: '400italic';
}
@font-face {
  font-family: '400italic';
  font-display: fallback;
  src: url('/assets/fonts/roboto-400italic.woff2') format('woff2');
}


:root {
  --typeface_500: '500';
}
@font-face {
  font-family: '500';
  font-display: fallback;
  src: url('/assets/fonts/roboto-500.woff2') format('woff2');
}

:root {
  --typeface_500italic: '500italic';
}
@font-face {
  font-family: '500italic';
  font-display: fallback;
  src: url('/assets/fonts/roboto-500italic.woff2') format('woff2');
}





/* Condensed */
:root {
  --typeface_condensed300: 'condensed300';
}
@font-face {
  font-family: 'condensed300';
  font-display: fallback;
  src: url('/assets/fonts/roboto-condensed-300.woff2') format('woff2');
}

:root {
  --typeface_condensed300italic: 'condensed300italic';
}
@font-face {
  font-family: 'condensed300italic';
  font-display: fallback;
  src: url('/assets/fonts/roboto-condensed-300italic.woff2') format('woff2');
}


:root {
  --typeface_condensed400: 'condensed400';
}
@font-face {
  font-family: 'condensed400';
  font-display: fallback;
  src: url('/assets/fonts/roboto-condensed-400.woff2') format('woff2');
}

:root {
  --typeface_condensed400italic: 'condensed400italic';
}
@font-face {
  font-family: 'condensed400italic';
  font-display: fallback;
  src: url('/assets/fonts/roboto-condensed-400italic.woff2') format('woff2');
}


:root {
  --typeface_condensed700: 'condensed700';
}
@font-face {
  font-family: 'condensed700';
  font-display: fallback;
  src: url('/assets/fonts/roboto-condensed-700.woff2') format('woff2');
}

:root {
  --typeface_condensed700italic: 'condensed700italic';
}
@font-face {
  font-family: 'condensed700italic';
  font-display: fallback;
  src: url('/assets/fonts/roboto-condensed-700italic.woff2') format('woff2');
}





/* Mono */
:root {
  --typeface_mono100: 'mono100';
}
@font-face {
  font-family: 'mono100';
  font-display: fallback;
  src: url('/assets/fonts/roboto-mono-100.woff2') format('woff2');
}

:root {
  --typeface_mono100italic: 'mono100italic';
}
@font-face {
  font-family: 'mono100italic';
  font-display: fallback;
  src: url('/assets/fonts/roboto-mono-100italic.woff2') format('woff2');
}


:root {
  --typeface_mono200: 'mono200';
}
@font-face {
  font-family: 'mono200';
  font-display: fallback;
  src: url('/assets/fonts/roboto-mono-200.woff2') format('woff2');
}

:root {
  --typeface_mono200italic: 'mono200italic';
}
@font-face {
  font-family: 'mono200italic';
  font-display: fallback;
  src: url('/assets/fonts/roboto-mono-200italic.woff2') format('woff2');
}


:root {
  --typeface_mono300: 'mono300';
}
@font-face {
  font-family: 'mono300';
  font-display: fallback;
  src: url('/assets/fonts/roboto-mono-300.woff2') format('woff2');
}

:root {
  --typeface_mono300italic: 'mono300italic';
}
@font-face {
  font-family: 'mono300italic';
  font-display: fallback;
  src: url('/assets/fonts/roboto-mono-300italic.woff2') format('woff2');
}


:root {
  --typeface_mono400: 'mono400';
}
@font-face {
  font-family: 'mono400';
  font-display: fallback;
  src: url('/assets/fonts/roboto-mono-400.woff2') format('woff2');
}

:root {
  --typeface_mono400italic: 'mono400italic';
}
@font-face {
  font-family: 'mono400italic';
  font-display: fallback;
  src: url('/assets/fonts/roboto-mono-400italic.woff2') format('woff2');
}


:root {
  --typeface_mono500: 'mono500';
}
@font-face {
  font-family: 'mono500';
  font-display: fallback;
  src: url('/assets/fonts/roboto-mono-500.woff2') format('woff2');
}

:root {
  --typeface_mono500italic: 'mono500italic';
}
@font-face {
  font-family: 'mono500italic';
  font-display: fallback;
  src: url('/assets/fonts/roboto-mono-500italic.woff2') format('woff2');
}


:root {
  --typeface_mono600: 'mono600';
}
@font-face {
  font-family: 'mono600';
  font-display: fallback;
  src: url('/assets/fonts/roboto-mono-600.woff2') format('woff2');
}

:root {
  --typeface_mono600italic: 'mono600italic';
}
@font-face {
  font-family: 'mono600italic';
  font-display: fallback;
  src: url('/assets/fonts/roboto-mono-600italic.woff2') format('woff2');
}


:root {
  --typeface_mono700: 'mono700';
}
@font-face {
  font-family: 'mono700';
  font-display: fallback;
  src: url('/assets/fonts/roboto-mono-700.woff2') format('woff2');
}

:root {
  --typeface_mono700italic: 'mono700italic';
}
@font-face {
  font-family: 'mono700italic';
  font-display: fallback;
  src: url('/assets/fonts/roboto-mono-700italic.woff2') format('woff2');
}





/* Icons */
:root {
  --typeface_icon400: 'icon400';
}
@font-face {
  font-family: 'icon400';
  font-display: fallback;
  src: url('/assets/fonts/fontawesome-400.woff2') format('woff2');
}


:root {
  --typeface_icon900: 'icon900';
}
@font-face {
  font-family: 'icon900';
  font-display: fallback;
  src: url('/assets/fonts/fontawesome-900.woff2') format('woff2');
}
